import React,{ useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
//import {Checkbox} from 'primereact/checkbox';
import { Container, Grid, Grid_Empresa, Botao, Logo, Label, 
        Linha, Tag,Mesa, Input, Separador, Titulo, LinhaRes, LinhaTitulo,TagIA, 
        BotaoVoltar, Espaco, Ultimo, Nome, InputRes, SeparadorIA, Plano, 
        Div, LinhaPlano, Li, Inputsel} from './style';
import LoadingSpinner from './spinner';
import Swal from 'sweetalert2';


//axios.defaults.baseURL = 'https://api.svcloud.com.br';
//axios_cep.defaults.baseURL = 'https://viacep.com.br/ws/';
let grid_position = 0;
type PlanosBaseProps ={
  planos: PlanosProps[];
}

type CEPProps ={
  cep: string;
  logradouro:	string;
  complemento:string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

type ErrorPros ={
  //message: string;
  //code: string;
  title: string;
  status: number;
  detail: string;
  traceId: string;
}

type PlanosProps ={
  id: number;
  nome: string;
  valor: string;
  itens: ItensProps[];
} 

type ItensProps = {
  id: number;
  nome: string;
  possui: number;
}

type ConsultaCNPJProps = {
  razao_social: string;
  estabelecimento: EstabelecimentoProps;
 
}

type CNPJCidadeProps = {
  nome: string;
  siafi_id: string;
  ibge_id: number;
  id: number;

}

type CNPJEstadoProps = {
  sigla: string;
  nome: string;
}

type CNPJIeProps = Ieprops[];

type Ieprops = {
  inscricao_estadual: string;
  estado: IeEstadoprops;
}

type IeEstadoprops = {
  id: number;
  nome: string;
  sigla: string;
  ibge_id: number;
}

type EstabelecimentoProps ={
  cnpj: string;
  nome_fantasia: string;
  cep: string;
  logradouro: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: CNPJCidadeProps;
  estado: CNPJEstadoProps;
  inscricoes_estaduais: CNPJIeProps;
} 

const Home = () =>{
  const grid_mesa = document.getElementById('grid_mesa');
  const [cnpj, setCNPJ] = useState('');
  const [ie, setIE] = useState('');
  const [im, setIM] = useState('');
  const [razao, setRazao] = useState('');
  const [fantasia, setFantasia] = useState('');
  const [cep, setCEP] = useState('');
  const [endereco, setEndereco] = useState('');
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [complemento, setComplemento] = useState('');
  const [telEmp, settelEmp] = useState('');
  const [crt, setCRT] = useState('');
  const [nome, setNome] = useState('');
  const [cpf, setCPF] = useState('');
  const [telefone, setTelefone] = useState('');
  const [data_nascimento, setDataNascimento] = useState('');
  const [email, setEmail] = useState('');
  const [usaInfos, setUsaInfos] = useState<number>(1);
  const [cobCep, setCobCep] = useState('');
  const [cobEnd, setCobEnd] = useState('');
  const [cobNum, setCobNum] = useState('');
  const [cobComplemento, setCobComplemento] = useState('');
  const [cobBairro, setCobBairro] = useState('');
  const [cobCidade, setCobCidade] = useState('');
  const [cobUF, setCobUF] = useState('');
  const [emailCob1, setEmailCob1] = useState('');
  const [emailCob2, setEmailCob2] = useState('');
  const [telCob, setTelCob] = useState('');
  const [sisUSer, setSisUser] = useState('');
  const [senhaUsu, setSenhaUsu] = useState('');
  const [confSenUsu, setConfSenUsu] = useState('');
  const [emailPortal, setEmailPortal] = useState ('');
  const [senhaPortal, setSenhaPortal] = useState ('');
  const [confSenhaPortal, setConfSenhaPortal] = useState('');
  const [planos, setPlanos] = useState<PlanosProps[]>([]);
  const [idselect, setidselect] = useState(0);
  const [usaSat, setUsaSat] =useState<number>();
  const [diaCobranca, setDiaCobranca] = useState<number>();
  const [tipoEmpresa, setTipoEmpresa] = useState<number>(0);
  const [assistencia, setAssistencia] = useState<number>();
  const [codRevenda, setCodRevenda] = useState('');
  const [qtMesa, setQtMesa] = useState('0');
  const [mesa, setMesa] = useState<boolean>(false);
  const [impPedido, setImpPedido] = useState<number>();
  const [taxa, setTaxa] = useState<number>();
  const [aCaixa, setACaixa] = useState<number>();
  const [fDelivery, setFDelivery] = useState<number>();
  const [zeraSenha, setZeraSenha] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);
  
  var plano: number;
const selecionaPlano = (id: number) => {
  setidselect(id);
  plano = id;
  let diaAtual = new Date;

  setDiaCobranca(diaAtual.getDate());
  if(planos[id-1].itens[2].possui===1){
    setUsaSat(1);
  }else{
    setUsaSat(0);
  }
  if(grid_usuario != null){
    grid_usuario.style.display = 'block';
  }
}

const ConsultaPlano = async () => {
  
  const retorno = await axios.get<PlanosBaseProps>("https://api.svcloud.com.br/Cadastro/ConsultaPlanos", config);
  setPlanos(retorno.data.planos);  
  }

  const config = {
    headers: { 
      'access': 'B8AB8420-C1BE-49EA-9E15-DA0ACE0C4230'
    }
  };

  const conf_cnpj = {
    headers: { 
      'access': 'B8AB8420-C1BE-49EA-9E15-DA0ACE0C4230',
      'cnpj': cnpj
    }
  };

  const ConsultaCNPJ = async (cnpj: string) => {
    
    const retorno = await axios.get<ConsultaCNPJProps>("https://api.svcloud.com.br/ConsultaCnpj/Consulta", conf_cnpj);
      setCNPJ(criaMascara('CNPJ',retorno.data.estabelecimento.cnpj,18));

      if(retorno.data.estabelecimento.inscricoes_estaduais === null){
        setIE('');
      }else{
        setIE(criaMascara('IE',retorno.data.estabelecimento.inscricoes_estaduais[0].inscricao_estadual,15));
      }

      setRazao(retorno.data.razao_social);

      if(retorno.data.estabelecimento.nome_fantasia ===  null){
        setFantasia('');
      }else{
        setFantasia(retorno.data.estabelecimento.nome_fantasia);
      }

      setCEP(criaMascara('CEP',retorno.data.estabelecimento.cep,10));
      setEndereco(retorno.data.estabelecimento.logradouro);
      setNumero(retorno.data.estabelecimento.numero);

      if(retorno.data.estabelecimento.complemento ===  null){
        setComplemento('');
      }else{
        setComplemento(retorno.data.estabelecimento.complemento);
      }
      
      setBairro(retorno.data.estabelecimento.bairro);
      setCidade(retorno.data.estabelecimento.cidade.nome);
      setEstado(retorno.data.estabelecimento.estado.sigla);
      
    }

  const ConsultaCEP = async (cep_: string, campo: number) => {
    if(campo ===0){
      const retorno = await axios.get<CEPProps>("https://viacep.com.br/ws/" + cep_.replace("-","") + "/json/");
      setCEP(criaMascara('CEP',retorno.data.cep,10));
      setEndereco(retorno.data.logradouro);
      setBairro(retorno.data.bairro);
      setCidade(retorno.data.localidade);
      setEstado(retorno.data.uf);
      setNumero("");
      setComplemento("");

     }
    else if(campo === 1){
      const retorno = await axios.get<CEPProps>("https://viacep.com.br/ws/" + cep_.replace("-","") + "/json/");
      setCobCep(criaMascara('CEP',retorno.data.cep,10));
      setCobEnd(retorno.data.logradouro);
      setCobBairro(retorno.data.bairro);
      setCobCidade(retorno.data.localidade);
      setCobUF(retorno.data.uf);
      setCobNum("");
      setComplemento("");
    }
  }
    const opcoes_informacoes = document.getElementById('usa_ac_auto');
    const grid_empresa = document.getElementById('grid_empresa');
    const grid_endereco = document.getElementById('grid_endereco');
    const grid_responsavel = document.getElementById('grid_responsavel');
    const grid_cobranca = document.getElementById('grid_cobranca');
    const grid_planos = document.getElementById('grid_planos');
    const grid_usuario = document.getElementById('grid_usuario');
    const grid_adicionais = document.getElementById('grid_adicionais');
    const grid_restaurante = document.getElementById('grid_restaurante');

    const btn_avancar = document.getElementById('btn_avancar');
    const btn_voltar = document.getElementById('btn_voltar');

    const cad_Empresa = {
      empresa_cnpj: cnpj,
      empresa_razao_social: razao,
      empresa_fantasia: fantasia,
      empresa_cep: cep,
      empresa_logradouro: endereco,
      empresa_numero: numero,
      empresa_bairro: bairro,
      empresa_complemento: complemento,
      empresa_uf: estado,
      empresa_cidade: cidade,
      responsavel_nome: nome,
      responsavel_cpf: cpf,
      cobranca_idem: usaInfos,
      cobranca_wp_sms: 1,
      empresa_ie: ie,
      empresa_im: im,
      empresa_telefone1: telEmp,
      empresa_telefone2: '',
      empresa_email: email,
      empresa_obs: '',
      empresa_crt: '',
      responsavel_telefone: telefone,
      responsavel_email1: email,
      responsavel_email2: '',
      responsavel_data_nascimento: data_nascimento,
      cobranca_telefone: telCob,
      cobranca_email1: emailCob1,
      cobranca_email2: emailCob2,
      cobranca_cep: cobCep,
      cobranca_logradouro: cobEnd,
      cobranca_numero: cobNum,
      cobranca_bairro: cobBairro,
      cobranca_complemento: cobComplemento,
      cobranca_uf: cobUF,
      cobranca_cidade: cobCidade,
      dia_vencimento: diaCobranca,
      plano: idselect,
      id_despesas_nfe_padrao: 0,
      impreme_pedido: impPedido,
      mesas_qtd: qtMesa,
      txgarcom: taxa,
      sat_fiscal: usaSat,
      delivery: fDelivery,
      assistencia_tecnica: assistencia,
      cx_inicial_automatico: aCaixa,
      delivery_zera_codigo: zeraSenha,
      sistema_usuario: sisUSer,
      sistema_senha: senhaUsu,
      email: emailPortal,
      senha: senhaPortal,
      revendedor: codRevenda
    }
    
    const salvar_dados = () => {

      if(tipoEmpresa !==2){
        setMesa(false);
        setQtMesa('0');
        setTaxa(0);
        setFDelivery(0);
        setZeraSenha(0);
      }

      setIsLoading(true);

      if(btn_voltar != null && btn_avancar != null){

        btn_voltar.style.visibility='hidden';
        btn_avancar.style.visibility='hidden';

      axios.post("https://api.svcloud.com.br/Cadastro/Empresa",cad_Empresa,config)
      .then(()=>{
        Swal.fire({position: 'center',
                   icon: 'success',
                   title: 'Parabéns pela venda',
                   text:"Cadastro realizado com sucesso",
                   showConfirmButton: true,
                   confirmButtonColor: "#0089D7"
                   //timer: 5000
                  });

        window.location.replace("http://svcloud.com.br/");
        setIsLoading(false);
      })
      .catch((error) => {
        if( error.response ){
          if(error.response.data.detail === undefined){
            Swal.fire({position: 'center',
                       icon: 'error',
                       title: 'Erro ao salvar as informações',
                       text:  error.response.data.status +'\nMensagem: Contate a equipe de desenvolvimento',
                       showConfirmButton: true,
                       confirmButtonColor: "#0089D7"
                       //timer: 5000
                      });
          }
          else{
            Swal.fire({position: 'center',
                       icon: 'error',
                       title: 'Erro ao salvar as informações',
                       text:  error.response.data.status + '\nMensagem: ' + error.response.data.detail,
                       showConfirmButton: true,
                       confirmButtonColor: "#0089D7"
                       //timer: 5000
                      });

                  btn_voltar.style.visibility='visible';
                  btn_avancar.style.visibility='visible';
                  grid_position = 5
          }
        }
        setIsLoading(false);
    })
  }
      
    }

    const grid_view = (position: number) => {
      if (grid_empresa != null && grid_endereco != null && grid_responsavel != null &&
          grid_planos != null && grid_adicionais!= null && btn_voltar != null && 
          btn_avancar != null && grid_restaurante != null && opcoes_informacoes != null &&
          grid_cobranca != null && grid_usuario != null) {
        opcoes_informacoes.style.pointerEvents = 'none';
        if (grid_position === 0) {                 
          grid_empresa.style.display = 'flex';
          grid_endereco.style.display = 'none';
          grid_responsavel.style.display = 'none';
          grid_cobranca.style.display = 'none';    
          grid_planos.style.display = 'none';
          grid_usuario.style.display = 'none';
          grid_adicionais.style.display = 'none'; 
          grid_restaurante.style.display= 'none';
          btn_avancar.textContent = 'Avançar';
          btn_voltar.style.visibility = 'hidden'; 
        }
        if (grid_position === 1 && (fantasia === '' || cnpj === '' || razao === '')){
          console.log(`CNPJ: ${cnpj}`);
          console.log(`RAZAO SOCIAL: ${razao}`);  
          console.log(`NOME FANTASIA: ${fantasia}`);
          Swal.fire({position: 'center',
                       icon: 'error',
                       title: 'Faltam informações',
                       text: 'Existem campos obrigatórios (*) vazios',
                       showConfirmButton: true,
                       confirmButtonColor: "#0089D7"
                       //timer: 5000
                      });
          grid_position = 0;
          return;

              //usar para teste
              /*grid_empresa.style.display = 'none';
              grid_endereco.style.display = 'block';   
              grid_responsavel.style.display = 'none';
              grid_cobranca.style.display = 'none';     
              grid_planos.style.display = 'none';
              grid_usuario.style.display = 'none';
              grid_adicionais.style.display = 'none';     
              grid_restaurante.style.display= 'none';
              btn_avancar.textContent = 'Avançar';
              btn_voltar.style.visibility = 'visible';*/

        }else{
          if (grid_position === 1) {
            grid_empresa.style.display = 'none';
            grid_endereco.style.display = 'block';   
            grid_responsavel.style.display = 'none';
            grid_cobranca.style.display = 'none';     
            grid_planos.style.display = 'none';
            grid_usuario.style.display = 'none';
            grid_adicionais.style.display = 'none';     
            grid_restaurante.style.display= 'none';
            btn_avancar.textContent = 'Avançar';
            btn_voltar.style.visibility = 'visible'; 
          } 
          if (grid_position === 2 && (cep === '' || endereco === '' || numero === '' || bairro === '' || cidade === '' || estado === '' || telEmp === '')){
            console.log(`CEP: ${cep}`);
            console.log(`ENDEREÇO: ${endereco}`);
            console.log(`NUMERO: ${numero}`);
            console.log(`BAIRRO: ${bairro}`);
            console.log(`CIDADE: ${cidade}`);
            console.log(`UF: ${estado}`);
            console.log(`TELEFONE: ${telEmp}`);
            Swal.fire({position: 'center',
                       icon: 'error',
                       title: 'Faltam informações',
                       text: 'Existem campos obrigatórios (*) vazios',
                       showConfirmButton: true,
                       confirmButtonColor: "#0089D7"
                       //timer: 5000
                      });
            grid_position = 1;
            return;

                //Usar para teste
                /*grid_empresa.style.display = 'none';
                grid_endereco.style.display = 'none';  
                grid_responsavel.style.display = 'block';
                grid_cobranca.style.display = 'none';     
                grid_planos.style.display = 'none';
                grid_usuario.style.display = 'none'; 
                grid_adicionais.style.display = 'none';
                grid_restaurante.style.display= 'none';
                btn_voltar.style.visibility = 'visible';  
                btn_avancar.textContent = 'Avançar';*/

          }else{
            if (grid_position === 2) {
              grid_empresa.style.display = 'none';
              grid_endereco.style.display = 'none';  
              grid_responsavel.style.display = 'block';
              grid_cobranca.style.display = 'none';     
              grid_planos.style.display = 'none';
              grid_usuario.style.display = 'none'; 
              grid_adicionais.style.display = 'none';
              grid_restaurante.style.display= 'none';
              btn_voltar.style.visibility = 'visible';  
              btn_avancar.textContent = 'Avançar';     
            }
            if (grid_position >= 3 && (nome === '' || cpf === '' || telefone === '' || email === '' || senhaPortal === '' || confSenhaPortal !== senhaPortal)){
              if(senhaPortal !== confSenhaPortal){
                console.log(`SENHA PORTAL: ${senhaPortal !== ''}`);
                console.log(`CONFIRMA SENHA PORTAL: ${confSenhaPortal === senhaPortal}`);
                Swal.fire({position: 'center',
                       icon: 'error',
                       title: 'Erro nas senhas',
                       text: 'As senhas não coincidem',
                       showConfirmButton: true,
                       confirmButtonColor: "#0089D7"
                       //timer: 5000
                      });
              }else{
                console.log(grid_position);
                console.log(`NOME: ${nome}`);
                console.log(`CPF: ${cpf}`);
                console.log(`TELEFONE: ${telefone}`);
                console.log(`E-MAIL: ${email}`);
                console.log(`SENHA PORTAL: ${senhaPortal !== ''}`);
                console.log(`CONFIRMA SENHA PORTAL: ${confSenhaPortal === senhaPortal}`);
                Swal.fire({position: 'center',
                       icon: 'error',
                       title: 'Faltam informações',
                       text: 'Existem campos obrigatórios (*) vazios',
                       showConfirmButton: true,
                       confirmButtonColor: "#0089D7"
                       //timer: 5000
                      });
              }
              grid_position = 2;
              return;

                  //Usar para teste
                  /*grid_empresa.style.display = 'none';
                  grid_endereco.style.display = 'none';   
                  grid_responsavel.style.display = 'none';
                  grid_cobranca.style.display = 'block';     
                  grid_planos.style.display = 'none';
                  grid_usuario.style.display = 'none';
                  grid_adicionais.style.display = 'none';     
                  grid_restaurante.style.display= 'none';
                  btn_avancar.textContent = 'Avançar';
                  btn_voltar.style.visibility = 'visible';*/
        
            }else{
              if (grid_position === 3) {
                grid_empresa.style.display = 'none';
                grid_endereco.style.display = 'none';   
                grid_responsavel.style.display = 'none'; 
                grid_cobranca.style.display = 'block';    
                grid_planos.style.display = 'none';
                grid_usuario.style.display = 'none';
                grid_adicionais.style.display = 'none';     
                grid_restaurante.style.display= 'none';
                btn_avancar.textContent = 'Avançar';
                btn_voltar.style.visibility = 'visible'; 
              }
              if (usaInfos === 0 && grid_position === 4 && (cobCep === '' || cobEnd === '' || cobNum === '' || cobBairro === '' || cobCidade === '' || cobUF === '' || telCob === '')){
                console.log(`CEP COBRANCA: ${cobCep}`);
                console.log(`ENDEREÇO COBRANCA: ${cobEnd}`);
                console.log(`NUMERO COBRANCA: ${cobNum}`);
                console.log(`BAIRRO COBRANCA: ${cobBairro}`);
                console.log(`CIDADE COBRANCA: ${cobCidade}`);
                console.log(`UF COBRANCA: ${cobUF}`);
                console.log(`TELEFONE COBRANCA: ${telCob}`);
                Swal.fire({position: 'center',
                       icon: 'error',
                       title: 'Faltam informações',
                       text: 'Existem campos obrigatórios (*) vazios',
                       showConfirmButton: true,
                       confirmButtonColor: "#0089D7"
                       //timer: 5000
                      });
                grid_position = 3;
                return;

                    //Usar para teste
                    /*grid_empresa.style.display = 'none';
                    grid_endereco.style.display = 'none';  
                    grid_responsavel.style.display = 'none';
                    grid_cobranca.style.display = 'none';     
                    grid_planos.style.display = 'block';
                    if(idselect > 0){
                      grid_usuario.style.display = 'block';
                    }else{
                      grid_usuario.style.display = 'none';
                    }
                    grid_adicionais.style.display = 'none';
                    grid_restaurante.style.display= 'none'; 
                    btn_voltar.style.visibility = 'visible'; 
                    btn_avancar.textContent = 'Avançar';*/

              }else{
                if (grid_position === 4) {
                  grid_empresa.style.display = 'none';
                  grid_endereco.style.display = 'none';  
                  grid_responsavel.style.display = 'none';
                  grid_cobranca.style.display = 'none';     
                  grid_planos.style.display = 'block';
                  if(idselect > 0){
                    grid_usuario.style.display = 'block';
                  }else{
                    grid_usuario.style.display = 'none';
                  }
                  grid_adicionais.style.display = 'none';
                  grid_restaurante.style.display= 'none'; 
                  btn_voltar.style.visibility = 'visible'; 
                  btn_avancar.textContent = 'Avançar';     
                }
                if (grid_position === 5 && (idselect <= 0 || sisUSer === '' || senhaUsu === '' || confSenUsu !== senhaUsu)){
                  if (senhaUsu !== confSenUsu){
                      console.log(`SENHA: ${(senhaUsu !== '')}`);
                      console.log(`CONFIRMA SENHA: ${confSenUsu === senhaUsu}`);
                      Swal.fire({position: 'center',
                       icon: 'error',
                       title: 'Erro nas senhas',
                       text: 'As senhas não coincidem',
                       showConfirmButton: true,
                       confirmButtonColor: "#0089D7"
                       //timer: 5000
                      });
                    }else{
                      console.log(`ID PLANO: ${idselect}`);
                      console.log(`USUARIO: ${sisUSer}`);
                      console.log(`SENHA: ${(senhaUsu !== '')}`);
                      console.log(`CONFIRMA SENHA: ${confSenUsu === senhaUsu}`);
                      Swal.fire({position: 'center',
                       icon: 'error',
                       title: 'Faltam informações',
                       text: 'Existem campos obrigatórios (*) vazios',
                       showConfirmButton: true,
                       confirmButtonColor: "#0089D7"
                       //timer: 5000
                      });
                    }
                    grid_position = 4;
                    return;

                        //Usar para teste
                        /*grid_empresa.style.display = 'none';
                        grid_endereco.style.display = 'none';  
                        grid_responsavel.style.display = 'none';
                        grid_cobranca.style.display = 'none';     
                        grid_planos.style.display = 'none';
                        grid_usuario.style.display = 'none';
                        grid_adicionais.style.display = 'block';
                        defTipo(tipoEmpresa);
                        if(tipoEmpresa > 0){
                          opcoes_informacoes.style.pointerEvents = 'auto';
                        } else{
                          opcoes_informacoes.style.pointerEvents = 'none';
                        }
                        btn_voltar.style.visibility = 'visible'; 
                        btn_avancar.textContent = 'Salvar';*/

                }else{
                  if (grid_position === 5) {
                    grid_empresa.style.display = 'none';
                    grid_endereco.style.display = 'none';  
                    grid_responsavel.style.display = 'none';
                    grid_cobranca.style.display = 'none';     
                    grid_planos.style.display = 'none';
                    grid_usuario.style.display = 'none';
                    grid_adicionais.style.display = 'block';
                    defTipo(tipoEmpresa);
                    if(tipoEmpresa > 0){
                      opcoes_informacoes.style.pointerEvents = 'auto';
                    } else{
                      opcoes_informacoes.style.pointerEvents = 'none';
                    }
                    btn_voltar.style.visibility = 'visible'; 
                    btn_avancar.textContent = 'Salvar';     
                  }
                  if (grid_position === 6 && (tipoEmpresa === null || codRevenda === '')){
                    if(codRevenda === ''){
                      console.log(`CODIGO DA REVENDA: ${codRevenda}`);
                      console.log(`TIPO EMPRESA: ${tipoEmpresa}`);
                      Swal.fire({position: 'center',
                       icon: 'error',
                       title: 'Faltam informações',
                       text: 'Coloque o código da revenda',
                       showConfirmButton: true,
                       confirmButtonColor: "#0089D7"
                       //timer: 5000
                      });
                    }else{
                      console.log(`CODIGO DA REVENDA: ${codRevenda}`);
                      console.log(`TIPO EMPRESA: ${tipoEmpresa}`);
                      Swal.fire({position: 'center',
                       icon: 'error',
                       title: 'Faltam informações',
                       text: 'Escolha um tipo de empresa',
                       showConfirmButton: true,
                       confirmButtonColor: "#0089D7"
                       //timer: 5000
                      });
                    }
                    grid_position = 5;
                    return;

                  }else if(grid_position === 6 && (tipoEmpresa != null || codRevenda !== '')){
                    salvar_dados();
                    grid_position = 5;
                  }
                }
              }
            }
          }
        }


        if (grid_position >= 7)
          grid_position = 5;
        if (grid_position < 0)
          grid_position = 0;
        
      }
    }

    function defTipo(i: number){
      setTipoEmpresa(i);
      if(opcoes_informacoes != null){
        opcoes_informacoes.style.pointerEvents = 'auto';
      }

      if(i === 2 && grid_restaurante != null && grid_mesa != null){
        grid_restaurante.style.display= 'block';
        if(mesa){
          grid_mesa.style.display='block';
        }
        else{
          grid_mesa.style.display='none';
        }
        setImpPedido(1);
      }else if(i !== 2 && grid_restaurante != null){
        grid_restaurante.style.display= 'none';
        setImpPedido(0);
        if(i === 3){
          setAssistencia(1);
        }else{
          setAssistencia(0);
        }
      }

    }

    function defMesa(){
      setMesa(!mesa);
        if(mesa){
          if(grid_mesa != null){
            grid_mesa.style.display= 'none';
          }
        }else{
          if(grid_mesa != null){
            grid_mesa.style.display= 'inline-block';
          }
        }
    }

    function usaTaxa(){
      let valor
      if(taxa === 1){
        valor = 0;
      }else{
        valor = 1;
      }
      setTaxa(valor);
    }

    function zeraAbertura(){
      let valor
      if(zeraSenha === 1){
        valor = 0;
      }else{
        valor = 1;
      }
      setZeraSenha(valor);
    }

    function usaAC(){
      let valor
      if(aCaixa === 1){
        valor = 0;
      }else{
        valor = 1;
      }
      setACaixa(valor);
    }

    function defDelivery(){
      let valor
      if(fDelivery === 1){
        valor = 0;
      }else{
        valor = 1;
      }
      setFDelivery(valor);
    }

    function usarDados(){
      let valor

      if(usaInfos === 1){
        setCobCep('');
        setCobEnd('');
        setCobNum('');
        setCobComplemento('');
        setCobBairro('');
        setCobCidade('');
        setCobUF('');
        setTelCob('');
        valor = 0
      }else{
        setCobCep(cep);
        setCobEnd(endereco);
        setCobNum(numero);
        setCobComplemento(complemento);
        setCobBairro(bairro);
        setCobCidade(cidade);
        setCobUF(estado);
        setTelCob(telefone);
        setEmailCob1(email);
        valor = 1;
      }
      setUsaInfos(valor);
    }

    function usaEmail(valor: string){
      setEmail(valor);
      setEmailPortal(valor);
    }

    function confirmaSenha(valor: string){
      if(senhaUsu === valor){
        setConfSenUsu(valor);
      }else{
        Swal.fire({position: 'center',
                   icon: 'warning',
                   title: 'Erro nas senhas',
                   text: 'Senha de usuário e confirmação não correspondem',
                   showConfirmButton: true,
                   confirmButtonColor: "#0089D7"
                   //timer: 5000
        });
      }
    }

    function confirmaPortal(valor: string){
      if(senhaPortal === valor){
        setConfSenhaPortal(valor);
      }else{
        Swal.fire({position: 'center',
                   icon: 'warning',
                   title: 'Erro nas senhas',
                   text: 'Senha do portal e confirmação não correspondem',
                   showConfirmButton: true,
                   confirmButtonColor: "#0089D7"
                   //timer: 5000
        });
      }
    }

    function criaMascara(mascaraInput: string, campo:string,  maxLength: number){
      const maximoInput = maxLength;
      let valorInput = campo;
      let valorSemPonto = valorInput.replace(/([^0-9])+/g, "");
      const mascaras = {
        CPF: valorInput.replace(/[^\d]/g, "").replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"),
        CELULAR: valorInput.replace(/[^\d]/g, "").replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3"),
        TELEFONE: valorInput.replace(/[^\d]/g,"").replace(/^(\d{2})(\d{4})(\d{4})/,"($1) $2-$3"),
        CEP: valorInput.replace(/[^\d]/g, "").replace(/(\d{5})(\d{3})/, "$1-$2"),
        CNPJ: valorInput.replace(/[^\d]/g, "").replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"),
        DATANASC: valorInput.replace(/[^\d]/g, "").replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3"),
        DATANASC2: valorInput.replace(/[^\d]/g, "").replace(/(\d{2})(\d{2})(\d{2})/, "$1/$2/$3"),
        IE: valorInput.replace(/[^\d]/g, "").replace(/(\d{3})(\d{3})(\d{3})(\d{3})/, "$1.$2.$3.$4"),
        IE2: valorInput.replace(/[^\d]/g, "").replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, "$1.$2.$3.$4"),

      }
      //if(valorInput.length !== maximoInput){
        if(mascaraInput === 'CNPJ'){
          campo = mascaras.CNPJ;
        }
        if(mascaraInput === 'CEP'){
          campo = mascaras.CEP;
        }
        if(mascaraInput === 'CPF'){
          campo = mascaras.CPF;
        }
        if(mascaraInput === 'TEL' && valorSemPonto.length === 10){
          campo = mascaras.TELEFONE;
        }
        else if(mascaraInput === 'TEL' && valorSemPonto.length === 11){
          campo = mascaras.CELULAR;
        }
        if(mascaraInput === 'DATANASC' && valorSemPonto.length === 8){
          campo = mascaras.DATANASC;
        }
        else if(mascaraInput === 'DATANASC' && valorSemPonto.length === 6){
          campo = mascaras.DATANASC2;
        }
        if(mascaraInput === 'IE' && valorSemPonto.length === 12){
          campo = mascaras.IE;
        }
        else if(mascaraInput === 'IE' && valorSemPonto.length === 11){
          campo = mascaras.IE2;
        }
      /*}else{
        campo = valorSemPonto;
      }*/
        return campo;
    }

    function voltar(){
      if(usaInfos === 1 && grid_position === 4){
        grid_position = 2;
      }else{
        grid_position--;
      }
      
      grid_view(grid_position)
    }

    function avancar(){
      if(usaInfos === 1 && grid_position === 2){
        grid_position = 4;
      }else{
        grid_position++
      }
      
      grid_view(grid_position);
    }

    useEffect(() => {      
      ConsultaPlano();
    }, []);


const renderUser = (
  <div style={{width:'100%'}}>      
      <Grid_Empresa id='grid_empresa' >
        <LinhaTitulo>
          <Titulo id='Dados_Empresa' >Dados da Empresa</Titulo>
        </LinhaTitulo>
          <Separador style={{width: '94%', justifyContent: 'space-between'}}>
            <Label id='CNPJ' style={{width: '62%', maxWidth: '485px'}}>
            <Input style={{paddingLeft: '5px'}} type="cnpj" value={cnpj} id='CNPJInput' maxLength={18} onChange={(a) => setCNPJ(criaMascara('CNPJ',a.target.value,a.target.maxLength))} />
              <Tag className={ cnpj.length > 0 ? "Active" : ""} htmlFor="CNPJ">
                CNPJ*
              </Tag>
            </Label>

            <Botao onClick={() => ConsultaCNPJ(cnpj)}>Buscar</Botao>
          </Separador>
          <Separador style={{width:'94%', display: 'flex', flexWrap: 'wrap'}}>
            <Label id='IE'style={{width: '46%',minWidth: '220px'}}>
              <Input style={{paddingLeft: '5px'}} type="IE" value={ie} maxLength={15} onChange={(a) => setIE(criaMascara('IE',a.target.value,a.target.maxLength))} />
              <Tag className={ ie.length > 0 ? "Active" : ""} htmlFor="IE">
                Inscrição Estadual
              </Tag>
            </Label>
            <Label id='IM'style={{width: '46%',minWidth: '220px', marginTop: '5px'}}>
              <Input type="IM" value={im} onChange={(e) => setIM(e.target.value)} />
              <Tag className={ im.length > 0 ? "Active" : ""} htmlFor="IM">
                Inscrição Municipal
              </Tag>
            </Label>
          </Separador>
          <Separador style={{width:'94%'}}>
            <Label id='Razao'>
              <Input type="Razao" value={razao} onChange={(a) => setRazao(a.target.value)} />
              <Tag className={ razao.length ? "Active" : ""} htmlFor="Razao">
                Razão Social*
              </Tag>
            </Label>
          </Separador>
          <Separador style={{width:'94%'}}>
            <Label id='Fantasia'>
              <Input type="Fantasia" value={fantasia} onChange={(a) => setFantasia(a.target.value)} required />
              <Tag className={ fantasia.length ? "Active" : ""} htmlFor="Fantasia">
              Nome Fantasia*
              </Tag>
            </Label>
          </Separador>
        <Ultimo />
        </Grid_Empresa>

        <Grid id='grid_endereco'>
        <LinhaTitulo>
          <Titulo id='Dados_Endereço' >Endereço da Empresa</Titulo>
        </LinhaTitulo>
          <Separador style={{justifyContent:'space-between'}}>
            <Label id='CEP' style={{width: '65%', maxWidth: '200px'}}>
              <Input type="CEP" value={cep} maxLength={9} onChange={(a) => setCEP(criaMascara('CEP',a.target.value,a.target.maxLength))} />
              <Tag className={ cep.length ? "Active" : ""} htmlFor="CEP">
                CEP*
              </Tag>
            </Label>
            <Botao onClick={() => ConsultaCEP(cep,0)}>Buscar</Botao>
          </Separador>
          <Separador>
            <Label id='Endereco' style={{width: '90%', minWidth: '240px'}}>
              <Input type="Endereco" value={endereco} onChange={(a) => setEndereco(a.target.value)} />
              <Tag className={ endereco.length ? "Active" : ""} htmlFor="Endereco">
              Endereço*
              </Tag>
            </Label>
          <Label id='Numero' style={{width: '10%', minWidth: '100px', marginLeft: '10px'}}>
              <Input type="Numero" value={numero} onChange={(a) => setNumero(a.target.value)} />
              <Tag className={ numero.length ? "Active" : ""} htmlFor="Numero">
              Número*
              </Tag>
            </Label>
          </Separador>
          <Separador style={{display: 'flex', flexWrap: 'wrap'}}>
            <Label id='Bairro'style={{width: '43%', minWidth: '240px', marginBottom: '5px'}}>
              <Input type="Bairro" value={bairro} onChange={(a) => setBairro(a.target.value)} />
              <Tag className={ bairro.length ? "Active" : ""} htmlFor="Bairro">
              Bairro*
              </Tag>
            </Label>
            <Label id='Cidade' style={{width: '43%', minWidth: '240px', marginBottom: '5px'}}>
              <Input type="Cidade" value={cidade} onChange={(a) => setCidade(a.target.value)} />
              <Tag className={ cidade.length ? "Active" : ""} htmlFor="Cidade">
              Cidade*
              </Tag>
            </Label>
          <Label style={{width: '10%', minWidth: '100px', marginBottom: '5px'}}>
              <Input type="UF" value={estado} onChange={(a) => setEstado(a.target.value)} />
              <Tag className={ estado.length ? "Active" : ""} htmlFor="UF">
              Estado*
              </Tag>
            </Label>
          </Separador>
          <Separador style={{marginTop:'0px', marginBottom: '5px', display: 'flex', flexWrap: 'wrap'}}>
            <Label id='Complemento' style={{width: '49.5%'}}>
              <Input type="Complemento" value={complemento} onChange={(a) => setComplemento(a.target.value)} />
              <Tag className={ complemento.length ? "Active" : ""} htmlFor="Complemento">
              Complemento
              </Tag>
            </Label>
          <Label id='TELEMP' style={{width: '49%'}}>
              <Input type='tel' value={telEmp} maxLength={15} onChange={(a) => settelEmp(criaMascara('TEL',a.target.value,a.target.maxLength))} />
              <Tag className={ telEmp.length ? "Active" : ""} htmlFor="TELEMP">
              Telefone Empresarial*
              </Tag>
            </Label>
          </Separador>
        <Ultimo />
      </Grid>


      <Grid id='grid_responsavel'>
      <LinhaTitulo>
          <Titulo id='Dados_Responsavel' >Dados do Responsável</Titulo>
        </LinhaTitulo>
        <Separador>
          <Nome>
          <Label id='Nome'>
            <Input type="Nome" value={nome} onChange={(e) => setNome(e.target.value)} />
            <Tag className={ nome.length ? "Active" : ""} htmlFor="Nome">
              Nome*
            </Tag>
          </Label>
          </Nome>
        </Separador>              
          <Separador style={{display: 'flex', flexWrap: 'wrap'}}>
          <Label id='CPF' style={{width: '35%', minWidth: '180px', marginBottom: '5px'}}>
            <Input type='CPF' value={cpf} maxLength={14} onChange={(a) => setCPF(criaMascara('CPF',a.target.value,a.target.maxLength))} />
            <Tag className={ cpf.length ? "Active" : ""} htmlFor="CPF">
              CPF*
            </Tag>
          </Label>
          <Espaco />
            <Label id='Telfone_Resp' style={{width: '35%', minWidth: '180px', marginBottom: '5px'}}>
              <Input type='tel' value={telefone} maxLength={15} onChange={(a) => setTelefone(criaMascara('TEL',a.target.value,a.target.maxLength))} />
              <Tag className={ telefone.length ? "Active" : ""} htmlFor="Telfone_Resp">
                Telefone*
              </Tag>
            </Label>
          <Espaco />        
            <Label id='DATANASC' style={{width: '25%', minWidth: '150px', marginBottom: '5px' }}>
              <Input type="DATANASC" value={data_nascimento} maxLength={10} onChange={(a) => setDataNascimento(criaMascara('DATANASC',a.target.value,a.target.maxLength))} />
              <Tag className={ data_nascimento.length ? "Active" : ""} htmlFor="DATANASC">
                Data Nascimento
              </Tag>
            </Label>            
          </Separador>
        <Separador style={{marginTop: '0px'}}>
          <Label id='EMAIL'>
            <Input type='email' value={email} onChange={(a) => usaEmail(a.target.value)} />
            <Tag className={ email.length ? "Active" : ""} htmlFor="EMAIL1">
              E-mail*
            </Tag>
          </Label>
        </Separador>
        <Separador>
            <Label id='senha_Portal'>
              <Input type={'password'} value={senhaPortal} onChange={(a) => setSenhaPortal(a.target.value)} />
              <Tag className={ senhaPortal.length ? "Active" : ""} htmlFor="SENHAPORTAL">
                Senha do Portal*
              </Tag>
            </Label>
            <Espaco />
            <Label id='conf_senha_Portal'>
              <Input type={'password'} value={confSenhaPortal} onChange={(a) => setConfSenhaPortal(a.target.value)} onBlur={(a) => confirmaPortal(a.target.value)} />
              <Tag className={ confSenhaPortal.length ? "Active" : ""} htmlFor='CONFSENHAPORTAL'>
                Confirmar Senha do Portal*
              </Tag>
            </Label>
          </Separador>
        <Separador style={{display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start'}}>
          <Separador style={{width: '80%', marginLeft: '-05px', margin: 'none',maxWidth: '300px'}}>
            <Tag style={{marginRight: '0px', width: '80%', minWidth: '250px', maxWidth: '300px'}}>Usar informações para cobrança?</Tag>
          </Separador>
          <Separador style={{display: 'flex', alignItems: 'flex-start', width: '20px', minWidth: '20px', marginLeft: '0px'}}>
            <Input type={'checkbox'} id={'usa_infos'} style={{width: '18px'}} onChange={(a) => usarDados()} defaultChecked={true}/>
          </Separador>
        </Separador>
        <Ultimo />
      </Grid>


      <Grid id='grid_cobranca'>
        <LinhaTitulo>
          <Titulo id='Dados_Cobranca' >Endereço de Cobrança</Titulo>
        </LinhaTitulo>
          <Separador style={{justifyContent:'space-between'}}>
            <Label id='CobCEP' style={{width: '65%', maxWidth: '200px'}}>
              <Input type="CobCEP" value={cobCep} maxLength={9} onChange={(a) => setCobCep(criaMascara('CEP',a.target.value,a.target.maxLength))} />
              <Tag className={ cobCep.length ? "Active" : ""} htmlFor="COBCEP">
                CEP*
              </Tag>
            </Label>
            <Botao onClick={() => ConsultaCEP(cobCep,1)}>Buscar</Botao>
          </Separador>
          <Separador>
            <Label id='Endereco_Cobranca' style={{width: '90%', minWidth: '240px'}}>
              <Input type="cobEnd" value={cobEnd} onChange={(a) => setCobEnd(a.target.value)} />
              <Tag className={ cobEnd.length ? "Active" : ""} htmlFor="COBEND">
              Endereço*
              </Tag>
            </Label>
          <Label id='Numero_Cobranca' style={{width: '10%', minWidth: '100px', marginLeft: '10px'}}>
              <Input type="CobNum" value={cobNum} onChange={(a) => setCobNum(a.target.value)} />
              <Tag className={ cobNum.length ? "Active" : ""} htmlFor="COBNUM">
              Número*
              </Tag>
            </Label>
          </Separador>
          <Separador style={{display: 'flex', flexWrap: 'wrap'}}>
            <Label id='Bairro_Cobranca' style={{width: '44%', minWidth: '240px', marginBottom: '5px'}}>
              <Input type="cobBairro" value={cobBairro} onChange={(a) => setCobBairro(a.target.value)} />
              <Tag className={ cobBairro.length ? "Active" : ""} htmlFor="COBBAIRRO">
              Bairro*
              </Tag>
            </Label>
          <Label id='Cidade_Cobranca' style={{width: '44%', minWidth: '240px', marginBottom: '5px'}}>
              <Input type="cobCidade" value={cobCidade} onChange={(a) => setCobCidade(a.target.value)} />
              <Tag className={ cobCidade.length ? "Active" : ""} htmlFor="COBCIDADE">
              Cidade*
              </Tag>
            </Label>
          <Label id='UF_Cobranca' style={{width: '10%', minWidth: '100px', marginBottom: '5px'}}>
              <Input type="cobUF" value={cobUF} onChange={(a) => setCobUF(a.target.value)} />
              <Tag className={ cobUF.length ? "Active" : ""} htmlFor="COBUF">
              Estado*
              </Tag>
            </Label>
          </Separador>
          <Separador style={{marginTop:'0px', marginBottom: '5px', display: 'flex', flexWrap: 'wrap'}}>
            <Label id='Complemento_Cobranca' style={{width: '49.5%'}}>
              <Input type="cobComplemento" value={cobComplemento} onChange={(a) => setCobComplemento(a.target.value)} />
              <Tag className={ cobComplemento.length ? "Active" : ""} htmlFor="COBCOMPLEMENTO">
              Complemento
              </Tag>
            </Label>
          <Label id='TELEFONE_Cobranca' style={{width: '49%'}}>
              <Input type='tel' value={telCob} maxLength={15} onChange={(a) => setTelCob(criaMascara('TEL',a.target.value,a.target.maxLength))}/>
              <Tag className={ telCob.length ? "Active" : ""} htmlFor="TELCOB">
              Telefone de Cobrança*
              </Tag>
            </Label>
          </Separador>
          <Separador>
            <Label id='Email1_cob'>
              <Input type={'email'} value={emailCob1} onChange={(a) => setEmailCob1(a.target.value)}/>
              <Tag className={ emailCob1.length ? "Active" : ""} htmlFor="EMAILCOB1">
                E-mail de cobrança primário*
              </Tag>
            </Label>
          </Separador>
          <Separador>
            <Label id='Email2_cob'>
              <Input type={'email'} value={emailCob2} onChange={(a) => setEmailCob2(a.target.value)}/>
              <Tag className={ emailCob2.length ? "Active" : ""} htmlFor="EMAILCOB1">
                E-mail de cobrança secundário
              </Tag>
            </Label>
          </Separador>
        <Ultimo />
      </Grid>


      <Grid  id='grid_planos'>
      <LinhaTitulo>
          <Titulo id='Dados_Planos' >Planos do sistema</Titulo>
        </LinhaTitulo>
        <LinhaPlano style={{display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-around'}}>
        {
          
          planos.map(plano => {
            let bgcor
            let bgselect = '#020D3F'
            let bordersel = '#1CBAA4'
            if(plano.id === 1){
              bgcor = '#5babe3'
            }else if(plano.id === 2){
              bgcor = '#387fc0'
            } else if(plano.id === 3){
              bgcor = '#2971b7'
            } else{
              bgcor = '#1e58a3'
            }

            const cor = idselect===plano.id ? bgselect:bgcor;
            const corborda = idselect=== plano.id ? bordersel:'white';
            const listItems = plano.itens.filter(item => item.possui === 1).map((d) => <Li key={d.id}>{d.nome}</Li>);
            
            return<Plano style={{backgroundColor:cor,borderColor: corborda}} onClick={() => selecionaPlano(plano.id)}>
            <Div style={{textAlign: 'center', fontSize: '16px', marginBottom: '20px', marginTop:'15px'}}>{plano.nome}</Div>
            <Div style={{height:'100px'}}>
            {listItems}
            </Div>
            <Div style={{marginBottom:'-60px'}}>R${plano.valor}</Div>
            </Plano>
          }) 
        }
        </LinhaPlano>
      </Grid>
      <Grid id='grid_usuario'>
        <LinhaTitulo>
          <Titulo id='Informacoes_Usuario'>Dados de criação de usuário</Titulo>
        </LinhaTitulo>
        
          <Separador>
            <Label id='NomeUsuario'>
              <Input type='Usuario' value={sisUSer} onChange={(a => setSisUser(a.target.value))} />
              <Tag className={ sisUSer.length ? "Active" : ""} htmlFor="SISUSER">
                Nome de usuário*
              </Tag>
            </Label>
          </Separador>
          <Separador>
            <Label id='senha_User'>
              <Input type={'password'} value={senhaUsu} onChange={(a) => setSenhaUsu(a.target.value)} />
              <Tag className={ senhaUsu.length ? "Active" : ""} htmlFor="SENHAUSU">
                Senha do usuário*
              </Tag>
            </Label>
            <Espaco style={{marginLeft: '5px'}}/>
            <Label id='conf_senha_User' style={{marginLeft: '5px'}}>
              <Input type={'password'} value={confSenUsu} onChange={(a) => setConfSenUsu(a.target.value)}  onBlur={(a) => confirmaSenha(a.target.value)}/>
              <Tag className={ confSenUsu.length ? "Active" : ""} htmlFor="CONFSENUSU">
              Confirmar senha*
              </Tag>
            </Label>
          </Separador>
        
        <Ultimo />
      </Grid>

      <Grid id='grid_adicionais'>
        <LinhaTitulo>
          <Titulo>Informações da Revenda</Titulo>
        </LinhaTitulo>
        
          <Separador style={{alignItems:'start', justifyContent: 'flex-start'}}>
            <Label style={{width: '35%', maxWidth: '150px'}}>
              <Input type={'number'} id={'codRevenda'} value={codRevenda} onChange={(a) => setCodRevenda(a.target.value)}/>
              <Tag className={ codRevenda.length ? "Active": ""} htmlFor='codRevenda'>
                Revenda*
              </Tag>
            </Label>
          </Separador>
        
        <LinhaTitulo>
          <Titulo id='Informacoes_adicionais' >Informações Adicionais</Titulo>
        </LinhaTitulo>
        
        <Separador>
          <Tag style={{marginRight: '0px', width: '80%', minWidth: '250px', maxWidth: '300px', marginTop: '-50px'}}>Tipo de Empresa:*</Tag>
        </Separador>
          <Separador style={{width: '94%'}}>
            <Separador style={{width: '30%', justifyContent: 'center', minWidth: '0px'}}>
              <Inputsel type='radio' id='radiumbox1' name='Tipo_Empresa' onClick={(a) => defTipo(1)}/>
              <TagIA>Varejo</TagIA>
            </Separador>
            <Separador style={{width: '30%', justifyContent: 'center', minWidth: '0px'}}>
              <Inputsel type='radio' id='radiumbox2' name='Tipo_Empresa' onClick={(a) => defTipo(2)}/>
              <TagIA>Restaurante</TagIA>
            </Separador>
            <Separador style={{width: '30%', justifyContent: 'center', minWidth: '0px'}}>
              <Inputsel type='radio' id='radiumbox3' name='Tipo_Empresa' onClick={(a) => defTipo(3)}/>
              <TagIA>Assistência Técnica</TagIA>
            </Separador>
          </Separador>
        
          <SeparadorIA style={{marginLeft: '20px'}}>
            <TagIA>Usar valor de abertura de caixa automático?</TagIA>
            <Inputsel type='checkbox' id='usa_ac_auto' onChange={(a) => usaAC()}/>
          </SeparadorIA>
        
        <Ultimo />
      </Grid>
      
      <Grid id='grid_restaurante'>
        <LinhaTitulo>
          <Titulo id='Restaurante' >Restaurante</Titulo>
        </LinhaTitulo>
          <LinhaRes>
            <TagIA>Possui mesas?</TagIA> 
            <Inputsel type='checkbox' id='usa_mesa' checked={mesa} onChange={(a) => defMesa()}/>
          </LinhaRes>
          <Mesa id='grid_mesa' style={{display: 'flex', flexWrap: 'wrap'}}>
          <LinhaRes style={{width: '90%', marginTop: '8px'}}>
            <TagIA>Quantidade de mesas:</TagIA>
            <InputRes style={{width: '20%', maxWidth: '80px'}} type='number' id='qt_mesas' min={1} max={999} value={qtMesa} onChange={(a) => setQtMesa(a.target.value)}/>
          </LinhaRes>
          <LinhaRes style={{width: '45%'}}>
            <TagIA style={{marginBottom: '4px'}}>Cobra taxa de garçom:</TagIA>
            <Inputsel type='checkbox' id='tx_garcom' onChange={(a) => usaTaxa()}/>
          </LinhaRes>
          </Mesa>
          <LinhaRes>
            <TagIA>Faz delivery?</TagIA>
            <Inputsel type='checkbox' id='faz_delivery' onChange={(a) => defDelivery()}/>
          </LinhaRes>
          <LinhaRes>
            <TagIA>Zerar senha na abertura de caixa?</TagIA>
            <Inputsel type='checkbox' id='faz_delivery' onChange={(a) => zeraAbertura()}/>
          </LinhaRes>
        <Ultimo />
      </Grid>
      </div>);
      
  return (
    <Container>
      <Logo style={{margin: 'auto'}} src={require('../../images/logo-branco.png')} />
      {isLoading ? <LoadingSpinner /> : renderUser}
      <Linha>
      <Separador style={{display: 'flex', margin: 'auto',justifyContent: 'space-between'}}>
          <BotaoVoltar id='btn_voltar' onClick={() => voltar()}>Voltar</BotaoVoltar>
          <Botao id='btn_avancar' onClick={() => avancar()}>Avançar</Botao>
      </Separador>
      </Linha>
      
    </Container>
  );
}


export default Home;