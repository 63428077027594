import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 99%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Plano = styled.div`
    display: flex;
    width: 250px;
    height: 200px;
    flex-direction: column;
    border-radius : 20px;
    cursor: pointer;
    border-width: 5px;
    border-style:solid;
    margin: 5px;
    margin-bottom: 15px;
    align-items: center;
`;

export const Div = styled.div`
    displey: flex;
    width: 80%;
    justify-content: center;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: white;
    position: relative;
    margin-top: 5px;
    font-size: 20px;
`;

export const Li = styled.li`
    displey: flex;
    width: 80%;
    justify-content: center;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: white;
    position: relative;
    margin-top: 5px;
    font-size: 13px;
    list-style-type: disc;
`;

export const SeparaPlano = styled.div`
    display: flex;
    width: 50%;
    justify-content: center;
`;

export const LinhaPlano = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const Grid = styled.div`
    display: none;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
    width: 60%;
    min-width: 370px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    border:none solid;
    border-radius: 20px;
    padding-top:0.1%;
    padding-bottom:0.1%;
`;

export const Grid_Empresa = styled.div`
    display: block;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
    width: 60%;
    aling-itens: auto;
    min-width: 370px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    border:none solid;
    border-radius: 20px;
    padding-top:0.1%;
    padding-bottom:0.1%;
`;

export const Botao = styled.button`
    background-color: #0089D7;
    height: 40px;
    width: 100px;
    color: white;
    font-weight: bold;
    text-decoration: none;
    border-radius: 10px;
    border:none;
    font-size: 16px;
    box-shadow: inset;
    :hover{
        background-color: gray;
    }
`;

export const BotaoVoltar = styled.button`
    background-color: cian;
    height: 40px;
    width: 100px;
    color: black;
    font-weight: bold;
    text-decoration: none;
    border-radius: 10px;
    border:none;
    font-size: 16px;
    visibility: hidden;
    :hover{
        background-color: gray;
    }
`;

export const Logo = styled.img`
    display: block;
    width: 20%;
    max-width: 150px;
    min-width: 80px;
    margin: auto;
`;

export const Label = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 50px;
    width:100%;
    position: relative;
    :focus-within label{
        transform: translate(0, 12px) scale(0.75);
        color: #999;
        margin-top:0px;
    }
    .Active{
        transform: translate(0, 12px) scale(0.75);
        color: #999;
        margin-top:0px;
    }
`;
export const LabelRes = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 50px;
    width:100%;
    position: relative;
    :focus-within label{
        transform: translate(0, 12px) scale(0.75);
        color: #999;
        margin-top:0px;
    }
    .Active{
        transform: translate(0, 12px) scale(0.75);
        color: #999;
        margin-top:0px;
    }
`;

export const Titulo = styled.label`
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0px 12px;
    color: #000;
    pointer-events: none;
    position: absolute;
    font-weight: bold; 
`;

export const Input = styled.input`
    height: 40px;
    padding: 14px 0px 0px 5px;
    outline: 0;
    border: 1px solid #000;
    border-radius: 20px;
    background: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: bold;
    background-color: #F3F3F3;
    width: 100%;
`;

export const Inputsel = styled.input`
    height: 40px;
    padding: 14px 16px 0 10px;
    outline: 0;
    border: 1px solid #000;
    border-radius: 20px;
    background: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: bold;
    width: 18px;
    background-color: #F3F3F3;
    :hover{
        transform: scale(1.5);
        
    }
`;

export const InputRes = styled.input`
    height: 25px;
    padding: 0px 0px 0px 8px;
    border: 2px solid #000;
    border-radius: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: bold;
    background-color: #F3F3F3;
    margin-left: 5px;
    width: 100%;
`;

export const Tag = styled.label`
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0px 12px;
    color: #000;
    pointer-events: none;
    position: absolute;
    transform: translate(0, 26px) scale(1);
    transform-origin: top left;
    transition: all 0.2s ease-out;
    font-weight: bold;
    margin-top: -8px; 
    width: 100%;
`;
export const TagRes = styled.label`
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0px 12px;
    color: #000;
    pointer-events: none;
    position: absolute;
    transform: translate(0, 26px) scale(1);
    transform-origin: top left;
    transition: all 0.2s ease-out;
    font-weight: bold;
    margin-top: -8px; 
    width: 100%;
`;

export const Separador = styled.div`
    width: 95%;
    margin: auto;
    min-width: 200px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
`;

export const SeparadorIA = styled.div`
    width: 50%;
    display: flex;
    min-width: 200px;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
`;
export const Inscricao = styled.div`
    width: 35%;
    display: flex;
    min-width: 200px;
    align-items: left;
    flex-direction: row;
    justify-content: center;
`;
export const Espaco = styled.div`
    margin-left: 10px;
`;
export const Ultimo = styled.div`
    margin-bottom: 25px;
`;
export const UF = styled.div`
    width: 10%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin: 5px;
    
`;
export const Complemento = styled.div`
    width: 70%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
`;
export const Tel = styled.div`
    width: 30%;
    dislpay: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin-left: 5px;  
`;

export const Endereco = styled.div`
    width: 95%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    
`;
export const Num = styled.div`
    width: 10%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin: 5px
    
`;
export const Nome = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
`;
export const Dnasc = styled.div`
    width: 20%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
`;
export const Linha = styled.div`
    display: flex;
    width: 60%;
    height: 56px;
    margin: auto;
    min-width: 370px;
`;
export const Mesa = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
`;

export const Delivery = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
`;

export const TagIA = styled.label`
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
    color: #000;
    font-weight: bold;
`;

export const EspacoIA = styled.label`
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 10px;
    color: #000;
    font-weight: bold;
`;
export const LinhaIA = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    height: 15px;
    margin-left: 0px;
    margin-bottom: 10px;
    justify-content: flex-start;
    align-items: baseline;
`;
export const LinhaRes = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    height: 30px;
    margin-left: 20px;
    justify-content: flex-start center;
    align-items: center;
`;
export const LinhaTitulo = styled.div`
    display: flex;
    flex-direction: row;
    width: 99%;
    height: 56px;
    margin: 5px;
    justify-content: center;
`;