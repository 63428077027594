import React from "react";
import "./spinner.css";

export default function LoadingSpinner() {
  return (
    <div>
      <div className="grid" style={{display: 'flex', justifyContent: 'center'}}>
        <div className="spinner-container">
            <div className="loading-spinner">
            </div>
        </div>
      </div>
      <div style={{marginTop:'20px'}}>
        <label className="info">Só um momento enquanto salvamos as informações</label>
      </div>
    </div>
  );
}