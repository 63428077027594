import React from "react";
import {
    createBrowserRouter,
  } from "react-router-dom";
import Cadastro from "./pages/Cadastro/";
import Home from "./pages/Home/";
import Editar from "./pages/Editar";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/cadastro",
    element: <Cadastro />,
  },
  {
    path: "/editar",
    element: <Editar />,
  },
]);

export default router;