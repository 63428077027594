import React from "react";
import { Link } from "react-router-dom"

const Cadastro = () =>{
    return (
        <div>
            <Link to="/">testeeeeeeeee</Link>
            <h1>Teste</h1>
        </div>
    );
}

export default Cadastro;